import React from 'react'

function UserError({ message }) {
    return (
        <>

            <div className="alert alert-danger text-center my-5" role="alert">
                {message}
            </div>

        </>)
}

export default UserError