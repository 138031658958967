import React, { useContext, useState } from 'react'
import "./signup.css";
import { Link, useNavigate } from 'react-router-dom'
import Headers from '../../components/header/Headers';
import axios from 'axios'
import { AuthContext } from '../../context/AuthContext';
import Login from '../login/Login';


function Signup() {

  const [user, setUser] = useState("")

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [errors, setErrors] = useState({})
  const [getdata, setGetData] = useState([])
  const { loading, error, dispatch } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm



  const handleInput = (e) => {
    let { name, value } = e.target
    setUser({ ...user, [name]: value })
  }

  const Verify = () => {
    let localErrors = {}
    let ReturnVerify = true

    const addErrMsg = (msg, key) => {
      localErrors[key] = msg
      ReturnVerify = false
    }

    let exists = false
    const userExist = () => {
      if (localStorage.getItem('Signupdata')) {

        getdata.forEach(e => {
          if (e.email === user.email)
            exists = true

        });
        return exists
      }
    }

    // for username 
    if (!user.username) {
      addErrMsg('Name must be required', 'username')
    } else if (user.username.length < 2) {
      addErrMsg('Name Should be 2 chars', 'username')
    }


    if (!user.phone) {
      addErrMsg('Contact no  must be required', 'phone')
    } else if (user.phone.length < 10) {
      addErrMsg('Username Should be 10 chars', 'phone')
    }

    // for email 
    if (!user.email) {
      addErrMsg('Email must be required', 'email')
    } else if (!emailRegex.test(user.email)) {
      addErrMsg('Plese enter Valid Email format', 'email')
    }
    else if (userExist()) {
      addErrMsg('user Exist Already', 'email')
    }

    // for pass 
    if (!user.password) {
      addErrMsg('Email must be required', 'password')
    } else if (user.password.length < 8) {
      addErrMsg('Email Should be 8 chars', 'password')
    }
    // } else if (!passwordRegex.test(user.password)) {
    //   addErrMsg('Plese enter Valid password ', 'password')
    // }

    // for cpass
    if (user.password != user.cpass) {
      addErrMsg("Password Can't match", 'cpass')
    }

    // for terrms
    if (!user.terms) {
      addErrMsg('Terms and Conditon Must Be Checked ', 'terms')
    }
    setErrors(localErrors)
    return ReturnVerify
  }



  console.log(user);
  const navigater = useNavigate();

  const handleSubmit = async () => {
    try {
      setIsSubmitted(true)
      Verify()


      const res = await axios.post("https://stayback1.onrender.com/api/auth/Register", user)


      alert("Signup Success")
  
      navigater("/Login");

    } catch (error) {
      console.log("erroe is", error)
    }
  }

  return (
    <>
      <Headers />
      <div className='userbackground   '>

        <div className='p-1 mx-auto   main shadow rounded  signmain '>
          <div className='w-75 adj'>

            <h1 className='mx-5 m-1 text-white'>Sign up </h1>


            <div className="mb-1 rounded">
              <label htmlFor="exampleInputName" className="form-label" >Name</label>
              <input type="text" className="form-control mx-3 m-1 " id="exampleInputName" name='username' onChange={handleInput} placeholder='Enter your Name' />
              {isSubmitted && errors.username && <p className='text-danger '>{errors.username}</p>}
            </div>

            <div className="mb-1 rounded">
              <label htmlFor="exampleInputEmail" className="form-label">Email address</label>
              <input type="email" className="form-control mx-3 m-1 " id="exampleInputEmail" name='email' onChange={handleInput} placeholder='Enter Email' />
              {/* <div id="emailHelp" className="form-text ">We'll never share your email with anyone else.</div> */}
              {isSubmitted && errors.email && <p className='text-danger'>{errors.email}</p>}
            </div>

            <div className="mb-1 rounded">
              <label htmlFor="exampleInputPassword1" className="form-label">Contact No </label>
              <input type="phone" className="form-control mx-3 m-1 " id="exampleInputPassword1" onChange={handleInput} name='phone' placeholder='Enter Contact No..' />
              {isSubmitted && errors.phone && <p className='text-danger'>{errors.phone}</p>}
            </div>

            <div className="mb-1 rounded">
            <div style={{ position: 'relative' }}>
                <label htmlFor='Password'>Password</label>
                <input type={showPassword ? 'text' : 'password'} name='password' placeholder="Enter password" id="password" onChange={handleInput} className="lInput form-control m-3" />


                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '80%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>


              {isSubmitted && errors.password && <p className='text-danger'>{errors.password}</p>}

            </div>
            <div className="mb-1 rounded">
            <div style={{ position: 'relative' }}>
                <label htmlFor='cpass'> Confirm Password</label>
                <input type={showPassword ? 'text' : 'password'} name='cpass' placeholder="password" id="Confirm password" onChange={handleInput} className="lInput form-control m-3" />

{/* 
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '80%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button> */}
              </div>


              {isSubmitted && errors.cpass && <p className='text-danger'>{errors.cpass}</p>}
            </div>
            <div className="mb-1 rounded form-check">
              {/* <input type="checkbox" className="form-check-input mt-2" name='terms' onClick={handleInput} id="exampleCheckbox" /> */}
              {/* <label className="form-check-label  mt-2  " htmlFor="exampleCheckbox"> Accept All Term and Codition</label>
            {isSubmitted && errors.terms && <p className='text-danger'>{errors.terms}</p>} */}
            </div>
            <button type="submit" className="btn btn-primary my-3 " onClick={handleSubmit}>Submit</button>
            <br />
            <hr />
            <span className='text-white '> All ready Registerd </span> <Link className='text-primary' to="/login">Login here...</Link>
          </div>
        </div>
      </div>
      {/* <Footers/>  */}
    </>
  )
}
export default Signup


