import React, { useEffect, useState } from 'react'
import Headers from '../../components/header/Headers'

function Userprofile() {

    const [logData, setlogData] = useState("")

    const userdata = () => {

        const user = JSON.parse(localStorage.getItem("user"));
        setlogData(user);
    }
    console.log('profile data is', logData);

    useEffect(() => {
        userdata()
    }, [])
    return (
        <>
            <Headers />
            <div className='container pro'>
                <h2 className='username'>
                    Hello {logData.username}
                </h2>
                <div className='row mt-5'>
                    <div className='col-3 profile '>
                        <img className='profile' src="images/dummy.png" />

                    </div>
                    <div className='col-6 my-5'>
                

                        <h4>Name :    {logData.username}</h4>
                        <h4>Email Id  : {logData.email}</h4>
                        <h4>Contact   :{logData.phone}</h4>
                        <h6>Last Update   :{logData.createdAt}</h6>

                    </div>
                <button className='btn btn-primary'>Update</button>
                </div>
            </div>



        </>
    )
}

export default Userprofile