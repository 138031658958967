import axios from "axios";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./login.css";
import UserError from "./UserError";
import Success from "./Success";
import Headers from "../../components/header/Headers";

const Login = () => {
  const [credentials, setCredentials] = useState({
    username: undefined,
    password: undefined,
  });

  const [success, setsuccess] = useState(false)
  const { loading, error, dispatch } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const navigate = useNavigate()

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setsuccess(false)
    dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post("https://stayback1.onrender.com/api/auth/login", credentials);
      dispatch({ type: "LOGIN_SUCCESS", payload: res.data.details });
      // setsuccess(true)
      navigate("/");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data });
    }
  };

  return (
    <>
      <Headers />

      <div className="userbackground signmain">
        <div className="signmain">

          {error && (<UserError message="Invalid Credentials" />)}
          {success && (<Success message='Login success... ' />)}
          <div className="login  ">
            <div className="lContainer  adj w-75 ">
              <h1 className=' text-white'> Sign in </h1>
              <label htmlFor='email'>User Name</label>
              <input type="text" placeholder="username" id="username" onChange={handleChange} className="lInput form-control m-3" />
              <div style={{ position: 'relative' }}>
                <label htmlFor='Password'>Password</label>
                <input type={showPassword ? 'text' : 'password'} placeholder="password" id="password" onChange={handleChange} className="lInput form-control m-3" />


                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '65%',
                    transform: 'translateY(-50%)',
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </button>
              </div>




              <button disabled={loading} onClick={handleClick} className="w-50 btn btn-primary signinbtn">
                Login
              </button>
              {error && <span className="text-danger">{error.message}</span>}
              <span className='text-white'> You Don't have a Account ? </span>
              <Link className="text-primary" to='/signup'>   Create here...</Link>
            </div>
          </div>
        </div>

      </div>


    </>
  );
};

export default Login;
