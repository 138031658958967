import React from 'react'
import "../Alertwindow/Error.css"
import Headers from '../components/header/Headers'
import { Link } from 'react-router-dom'

function Errorpage() {
  return (
    <>
    <Headers/>
   <div className='ajdesterror'>
    <img src='/images/Error.png' alt='Page not Found ...!'  className= "" />
    <span className='mb-5'>Have some quary ? feel free to </span><Link to='/contact'><strong>Contact Us </strong> </Link>

   </div>
    
    </>
  )
}

export default Errorpage