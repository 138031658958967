
import React from 'react'

function Listland() {
  return (
    <section class="d-flex flex-column justify-content-center">
    <div class="container" data-aos="zoom-in" data-aos-delay="100">
      <h1>Raju lal</h1>
      <p>I'm <span class="typed" data-typed-items="MERN Full Stack Developer, Photographer"></span></p>
      <div class="social-links">
        <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
        <a href="https://instagram.com/rajkumarvishvkarma?utm_source=qr&igshid=ZDc4ODBmNjlmNQ%3D%3D" class="instagram"><i class="bx bxl-instagram"></i></a>
        <a href="https://wa.me/message/5AHYPHGP6M6RG1" class="google-plus"><i class="bx bxl-whatsapp"></i></a>
        <a href="https://www.linkedin.com/in/raju-lal-a3812018b/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
      </div>
    </div>
  </section>
  )
}

export default Listland