import useFetch from "../../hooks/useFetch";
import "./featured.css";

const Featured = () => {
  const { data, loading, error } = useFetch(
    "https://stayback1.onrender.com/api/hotels/countByCity?cities=jaipur,kota,london"
  );

  return (
    <div className="featured container ">
      <div className="row">

        {loading ? (
          "Loading please wait"
        ) : (
          <>
            <div className="featuredItem">
              <img
                src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/17/d3/a8/57/images-30-largejpg.jpg?w=500&h=-1&s=1"
                alt=""
                className="featuredImg"
              />
              <div className="featuredTitles">
                <h1>Jaipur</h1>
                <h2>{data[0]} properties</h2>
              </div>
            </div>

            <div className="featuredItem">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRrqYkPd3A9Dax9jgDdNnIdx1Q_qUUq3-QY_Q&s"
                alt=""
                className="featuredImg"
              />
              <div className="featuredTitles">
                <h1>Kota</h1>
                <h2>{data[1]} properties</h2>
              </div>
            </div>
            <div className="featuredItem">
              <img
                src="https://media.istockphoto.com/id/530741074/photo/red-fort-lal-qila-with-indian-flag-delhi-india.jpg?s=612x612&w=0&k=20&c=7BTI-dgQNPPTq2yARrwIBf2yIqO4PUPX1EJY5ITIyoM="
                alt=""
                className="featuredImg"
              />
              <div className="featuredTitles">
                <h1>Delhi</h1>
                <h2>{data[2]} properties</h2>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Featured;
