import React from 'react'

function Success({message}) {
    return (
        <>

            <div className="alert alert-success text-center my-5" role="alert">
                {/* Your Requset Fullfill Succesfully... */}
                {message}
            </div>

        </>
    )
}

export default Success