import React from 'react'
import './Contact.css'
import Headers from '../../components/header/Headers'
import Footers from '../../components/footer/Footers';

function Contact() {
    return (
        <>
        <Headers/>
             <div className='main'>
                
                <h3 className='manage text-white  '>“Reach out to us anytime and we'll happily answer your questions,”</h3>

                <img src='images/depo.jpg' alt='Contact Us'  className='conatctbg'/>
             </div>
                <div className="rt-container">
                    <div className="col-rt-12">
                        <div className="Scriptcontent">
                            <div>
                                <div className="container">
                                    <div className="contact-parent">
                                        <div className="contact-child child1">
                                            <p>
                                                <i className="fas fa-map-marker-alt"></i> Address <br />
                                                <span className='text-info'> 52 Staymenu Village Turkadi  <br/>Th.Hindoli District Bundi<br/> Rajasthan 
                                                323024 <br />
                                                
                                                </span>
                                            </p>

                                            <p>
                                                <i className="fas fa-phone-alt"></i> Let's Talk <br />
                                                <a className='text-info'  href="tel:+91 9462979594">+91 9462979594</a>
                                            </p>

                                            <p>
                                                <i className=" far fa-envelope"></i> General Support <br />
                                              
                                                  <a className='text-info' href="mailto:Staymenu.info@gmail.com">Staymenu.info@gmail.com</a>
                                            
                                            </p>
                                        </div>

                                        <div className="contact-child child2">
                                            <div className="inside-contact">
                                                <h2>Contact Us</h2>
                                                <h3>
                                                    <span id="confirm" />
                                                </h3>

                                                <p>Name *</p>
                                                <input id="txt_name" type="text" Required="required" />

                                                <p>Email *</p>
                                                <input id="txt_email" type="text" Required="required" />

                                                <p>Phone *</p>
                                                <input id="txt_phone" type="text" Required="required" />

                                                <p>Subject *</p>
                                                <input id="txt_subject" type="text" Required="required" />

                                                <p>Message *</p>
                                                <textarea id="txt_message" rows="4" cols="20" Required="required" ></textarea>

                                                <input type="submit" id="btn_send" value="SEND" className='bg-primary' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footers/>
        </>
    )
}

export default Contact;