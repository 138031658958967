import React, { useState } from 'react';
import { Link, } from 'react-router-dom';
import axios from 'axios';
import './List.css'
import Headers from '../header/Headers';


const Listrequest = () => {
    const [formData, setFormData] = useState({
        step: 1,
        name: '',
        email: '',
        contact: '',
        rooms: '',
        price: '',
        checkboxValues: [],
        images: [],
        address: [],
    });

    const [address, setAddress] = useState([{
        stap: 2,
        street: '',
        city: '',
        state: '',
        postalCode: '',
        country: ''
    }]);

    const handleaddress = (e) => {
        const { name, value } = e.target;


        if (address) {
            setAddress({ ...address, [name]: value });
        }

    };



    console.log({ address })

    const storeaddress = () => {
        setFormData(...formData, address)
        nextStep()
    }


    // check box section 
    const [checkboxValues, setCheckboxValues] = useState([]);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setCheckboxValues([...checkboxValues, value]);
        } else {
            setCheckboxValues(checkboxValues.filter((val) => val !== value));
        }
    };

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevData) => ({
            ...prevData, [name]: files,
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const nextStep = () => {
        
        setFormData(prevState => ({
            ...prevState,
            step: prevState.step + 1
        }));
        savedata();
    };

    const nextSteps = () => {
        
        setFormData(prevState => ({
            ...prevState,
            step: prevState.step + 1
        }));
        storeaddress()
    };

    const savedata = () => {
        setFormData({ ...formData, checkboxValues });
    
        nextStep();
    }


    const prevStep = () => {
        setFormData(prevState => ({
            ...prevState,
            step: prevState.step - 1
        }));
    };


    const handleSubmit = () => {
        console.log({ formData })
    }
    console.log({ formData });


    const handlefinalSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('contact', formData.contact);
        formDataToSend.append('rooms', formData.rooms);
        formDataToSend.append('price', formData.price);
        formDataToSend.append('address', formData.address);
        formDataToSend.append('checkboxValue', formData.checkboxValues);

        for (let i = 0; i < formData.images.length; i++) {
            formDataToSend.append('images', formData.images[i]);
        }
        try {
            const response = await axios.post('//listRequest', formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Hotel added successfully:', response.data);
            // You can perform additional actions after successful submission
        } catch (error) {
            console.error('Error adding hotel:', error);
        }
    };


    // console.log({formData});
    const review = () => {
        alert(formData.name)
    }
    const { step, email, contact, price, rooms } = formData;
    switch (step) {

        case 1:
            return (<>
                <Headers />

                <div className="rt-container mt-4">
                    <div className="col-md-12">
                        <div className="Scriptcontent">
                            
                                <div className="container">
                                    <div className="contact-parent">    
                                        <div>
                                            <img src='images/List.jpg' alt='List' />
                                        </div>

                                        <div className="contact-child child2">

                                            <div className=" container  ">
                                                <h2 className='ms-5 ps-5'>Basic About Property</h2>
                                                <div className="progressbar  text-secondary">
                                                    <div
                                                        style={{ width: "25%" }}
                                                    ></div>
                                                </div>
                                                <div className='inside-contact  pt-5'>

                                                    <form onSubmit={handleSubmit}>
                                                        <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Property Name" />
                                                        <input type="email" name="email" value={email} onChange={handleChange} placeholder="Email" />
                                                        <input type="contact" name="contact" value={contact} onChange={handleChange} placeholder="Contact" />
                                                        <input type="number" name="rooms" value={rooms} onChange={handleChange} placeholder="Available Rooms..." />
                                                        <input type="number" name="price" value={price} onChange={handleChange} placeholder="Desire Price Per rooms... " />
                                                    </form>
                                                    <button type="button" className='btn btn-primary' onClick={nextStep}>Next</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>      
                </div>
            </>
            );
        case 2:
            return (
                <>
                    <Headers />

                    <div className="rt-container mt-5">
                        <div className="col-rt-12">
                            <div className="Scriptcontent">
                                
                                    <div className="container">
                                        <div className="contact-parent">
                                            <div>
                                                <img src='List.jpg' width={550} height={600} alt='List' />
                                            </div>

                                            <div className="contact-child child2">

                                                <div className=" container  ">
                                                    <h2 className='ms-5 ps-5'>Address Details</h2>

                                                    <div className="progressbar  text-secondary">
                                                        <div
                                                            style={{ width: "50%" }}
                                                        ></div>
                                                    </div>
                                                    <div className='inside-contact  pt-5'>

                                                        <form onSubmit={handleSubmit}>
                                                            <input type="text" name="street" value={address.street} placeholder='Enter Streat' onChange={handleaddress} />

                                                            <input type="text" name="city" value={address.city} placeholder='Enter city' onChange={handleaddress} />

                                                            <input type="text" name="state" value={address.state} placeholder='Enter State' onChange={handleaddress} />

                                                            <input type="number" name="postalCode" value={address.postalCode} placeholder='Enter Area PostalCode' onChange={handleaddress} />

                                                            <input type="text" name="country" value={address.country} placeholder='Enter Country' onChange={handleaddress} />
                                                        </form>
                                                        <button type="button" className='btn btn-primary me-3' onClick={prevStep}>Previous</button>
                                                        <button type="button" className='btn btn-primary' onClick={nextSteps}>Next</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </>
            );

        case 3:
            return (
                <>
                    <Headers />
                    <div className="rt-container mt-5">
                        <div className="col-rt-12">
                            <div className="Scriptcontent">
                                
                                    <div className="container">
                                        <div className="contact-parent">
                                            <div>
                                                <img src='List.jpg' width={550} height={600} alt='List' />
                                            </div>

                                            <div className="contact-child child2">
                                                <div className=" container  ">
                                                    <h2 className='ms-5 ps-5'>Add Available Services</h2>
                                                    <div className="progressbar  text-secondary">
                                                        <div
                                                            style={{ width: "75%" }}
                                                        ></div>
                                                    </div>
                                                    <div className='inside-contact  pt-5'>

                                                        <form onSubmit={handleSubmit}>
                                                            <label className='changelable text-secondary'>
                                                                <input className='infix' type="checkbox" value="Wi-Fi" onChange={handleCheckboxChange} />
                                                                Wi-Fi
                                                            </label>
                                                            <label className='changelable text-secondary'>
                                                                <input className='infix' type="checkbox" value="Parking" onChange={handleCheckboxChange} />
                                                                Parking
                                                            </label>
                                                            <label className='changelable text-secondary'>
                                                                <input className='infix' type="checkbox" value="A.C." onChange={handleCheckboxChange} />
                                                                A.C.
                                                            </label>

                                                            <label className='changelable text-secondary'>
                                                                <input className='infix' type="checkbox" value="Parking" onChange={handleCheckboxChange} />
                                                                Parking
                                                            </label>
                                                            <label className='changelable text-secondary'>
                                                                <input className='infix' type="checkbox" value="Parking" onChange={handleCheckboxChange} />
                                                                Parking
                                                            </label>
                                                            <label className='changelable text-secondary'>
                                                                <input className='infix' type="checkbox" value="Parking" onChange={handleCheckboxChange} />
                                                                Parking
                                                            </label>
                                                        </form>
                                                        <div className='mt-5 py-5'>
                                                            <button type="button mt-5" className='btn btn-primary m-3' onClick={prevStep}>Previous</button>
                                                            <button type="button" className='btn btn-primary' name='services' onClick={savedata}>Next</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )

        default:
            return (
                <>
                    <Headers />
                    <div className="rt-container mt-5">
                        <div className="col-rt-12">
                            <div className="Scriptcontent">
                                    <div className="container">
                                        <div className="contact-parent">
                                            <div>
                                                <img src='List.jpg' width={550} height={600} alt='List' />
                                            </div>
                                            <div className="contact-child child2">
                                                <div className=" container  ">
                                                    <h2 className='ms-5 ps-5'>Upload Images </h2>
                                                    <div className="progressbar  text-secondary">
                                                        <div
                                                            style={{ width: "100%" }}
                                                        ></div>
                                                    </div>
                                                    <div className='inside-contact  pt-5'>

                                                        <form onSubmit={handleSubmit}>
                                                            <div className=" container body personal-info-container mt-5 ">
                                                                <label className='mb-3'> Image Inputs:</label><br />
                                                                <input type="file" name="images" onChange={handleImageChange} multiple />
                                                                <p className='text-info'>Please Select all Images Once for  Upload Images </p>

                                                                <input type="file" disabled name="images" onChange={handleImageChange} multiple />
                                                                <input type="file" disabled name="images" onChange={handleImageChange} multiple />
                                                            </div>
                                                        </form>
                                                        <div className="mt-5">
                                                            <button type="button" className='btn btn-primary' onClick={prevStep}>Previous</button>
                                                            <button type="button" className='btn btn-primary mx-3' onClick={review}>Review</button>
                                                            <button type="submit" className='btn btn-primary' onClick={handlefinalSubmit} >Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </>
            );
    }
};
export default Listrequest;
